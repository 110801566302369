import React from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { getRouteUrl } from '@utils/urls';

import { JOBS_OPERATION_AREA_DETAILS } from '@common/routes';

import {
  AreaImage,
  AreaTitle,
  Container,
  OperationArea,
  OperationsList,
  Title,
} from './AreaOfOperationList.styled';

const AreaOfOperationList = ({ value }) => {
  const { locale } = useRouter();
  const { heading, areaOfOperations } = value;

  return (
    <Container>
      <Title>{heading}</Title>

      <OperationsList>
        {areaOfOperations.map(item => (
          <Link
            href={{
              pathname: JOBS_OPERATION_AREA_DETAILS,
              query: {
                id: item.id,
                slug: item.slug,
              },
            }}
            as={getRouteUrl(locale, JOBS_OPERATION_AREA_DETAILS, {
              id: item.id,
              slug: item.slug,
            })}
            passHref
            legacyBehavior
            key={item.id}
          >
            <OperationArea key={item.id}>
              <AreaTitle>{item.title}</AreaTitle>
              {item.image && (
                <AreaImage
                  widths={[500, 450, 380, 430, 360, 280, 270, 420, 320]}
                  aspectRatios="3:2"
                  src={item.image}
                  alt={item.title}
                />
              )}
            </OperationArea>
          </Link>
        ))}
      </OperationsList>
    </Container>
  );
};

AreaOfOperationList.propTypes = {
  value: PropTypes.shape({
    areaOfOperations: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        image: PropTypes.string,
        slug: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
      }),
    ).isRequired,
    heading: PropTypes.string.isRequired,
  }).isRequired,
};

export default AreaOfOperationList;
