import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';
import format from 'date-fns/format';

import HTMLRenderer from '@common/components/CMSComponents/HTMLRenderer';
import { ArrowExternal } from '@common/components/Icons';
import { DAY_MONTH_YEAR_DOTS } from '@common/constants/dates';

import {
  Container,
  Description,
  Item,
  ItemButton,
  ItemDate,
  ItemsContainer,
  ItemSubtitle,
  ItemText,
  ItemTitle,
  Title,
} from './PressArticlesList.styled';

const PressArticlesList = ({ value }) => {
  const { title, items, description } = value;
  const { t } = useTranslation();

  return (
    <Container>
      <Title>{title}</Title>
      <ItemsContainer>
        {/* eslint-disable react/no-array-index-key */}
        {items.map((item, index) => {
          const date = new Date(item.date);

          return (
            <Item key={`${item.title}_${item.date}_${index}`}>
              <ItemDate>{format(date, DAY_MONTH_YEAR_DOTS)}</ItemDate>
              <ItemSubtitle>{item.subtitle}</ItemSubtitle>
              <ItemTitle>{item.title}</ItemTitle>
              <ItemText>
                <HTMLRenderer html={item.text} />
              </ItemText>
              <ItemButton
                href={item.url}
                target="_blank"
                rel="noopener noreferrer"
                iconRight={<ArrowExternal />}
              >
                {t(
                  'cmsBlock.pressStatementsList.item.linkText',
                  'Mitteilung lesen',
                )}
              </ItemButton>
            </Item>
          );
        })}
        {/* eslint-enable react/no-array-index-key */}
      </ItemsContainer>
      <Description>
        <HTMLRenderer html={description} />
      </Description>
    </Container>
  );
};

PressArticlesList.propTypes = {
  value: PropTypes.shape({
    description: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        date: PropTypes.string,
        subtitle: PropTypes.string,
        text: PropTypes.string,
        title: PropTypes.string,
        url: PropTypes.string,
      }),
    ),
    title: PropTypes.string,
  }).isRequired,
};

export default PressArticlesList;
