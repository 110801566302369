import React from 'react';
import PropTypes from 'prop-types';

import {
  Container,
  Title,
  FactsGridItem,
  GridContainer,
} from './FactsGridBlock.styled';

const FactsGridBlock = ({ value: { heading, items } }) => (
  <Container>
    <Title>{heading}</Title>
    <GridContainer>
      {items.map(({ title }) => (
        <FactsGridItem key={title}>{title}</FactsGridItem>
      ))}
    </GridContainer>
  </Container>
);

FactsGridBlock.propTypes = {
  value: PropTypes.shape({
    heading: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }).isRequired,
};
FactsGridBlock.defaultProps = {};

export default FactsGridBlock;
