import React from 'react';
import PropTypes from 'prop-types';

import {
  Backdrop,
  BackdropText,
} from '@common/components/Voucher/Voucher.styled';

import { GiftCardTop } from './BonTop';

export const InvalidCard = ({ message, priceTag, onClick }) => (
  <GiftCardTop
    isButton
    priceTag={priceTag}
    onClick={onClick}
    dataTestid="invalidCard"
  >
    <Backdrop>
      <BackdropText>{message}</BackdropText>
    </Backdrop>
  </GiftCardTop>
);

InvalidCard.propTypes = {
  message: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  priceTag: PropTypes.number,
};

InvalidCard.defaultProps = {
  message: undefined,
  priceTag: undefined,
};
