import styled from 'styled-components';

import {
  getGridBase,
  getThemeColor,
  getThemePath,
  getTypographyStyle,
  media,
} from '@utils/styled';

import LazyImg from '@common/components/LazyImg';
import Typography from '@common/components/Typography';
import { CMSBlockContainer } from '@common/components/CMSComponents/Container.styled';

export const Container = styled(CMSBlockContainer)`
  ${({ theme }) => theme.getFluidSpacing('margin-bottom', 'section', 3)};
`;

export const Title = styled(Typography).attrs({
  variant: 'h4',
})`
  ${({ theme }) => theme.getFluidSpacing('margin-bottom', 'scale', 4)};
`;

export const OperationsList = styled.div`
  ${getGridBase()}
  ${({ theme }) => theme.getFluidSpacing('grid-row-gap', 'widthBased', 1)}
`;

export const OperationArea = styled.a`
  position: relative;
  overflow: hidden;
  display: flex;
  grid-column: span 12;
  height: 0;
  border-radius: ${getThemePath(['borderRadiuses', 'normal.2'])}rem;
  padding-bottom: 66.73%;
  background: ${getThemeColor('bluegrey.95')};
  text-decoration: none;
  cursor: pointer;

  ${media.from480up`
    grid-column: span 6;
  `}

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    display: block;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.4) 100%
    );
  }
`;

export const AreaTitle = styled(Typography).attrs({
  customAs: 'span',
  variant: 'h5',
})`
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  margin: 0;
  color: ${getThemeColor('grey.100')};
  ${({ theme }) => theme.getFluidSpacing('padding', 'scale', 5)};

  ${media.from640up`
    ${getTypographyStyle('h4')};
  `}
`;

export const AreaImage = styled(LazyImg)`
  position: absolute;
  top: 0;
  left: 0;

  img {
    display: block;
  }
`;
