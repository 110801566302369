import React from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';

import { ArrowForwardNavigation } from '@common/components/Icons';

import {
  ShowAllItems,
  ShowAllItemsContent,
  ShowAllItemsIcon,
  ShowAllItemsText,
} from './ShowAllItemsCard.styled';

const ShowAllItemsCard = ({ linkHref, linkAs, buttonText, icon }) => (
  <Link legacyBehavior href={linkHref} passHref as={linkAs}>
    <ShowAllItems data-testid="showAllItems">
      <ShowAllItemsContent>
        <ShowAllItemsIcon icon={icon} />
        <ShowAllItemsText>{buttonText}</ShowAllItemsText>
      </ShowAllItemsContent>
    </ShowAllItems>
  </Link>
);

ShowAllItemsCard.defaultProps = {
  icon: <ArrowForwardNavigation />,
};

ShowAllItemsCard.propTypes = {
  linkHref: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      pathname: PropTypes.string,
      query: PropTypes.shape({}),
    }),
  ]).isRequired,
  linkAs: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  icon: PropTypes.node,
};

export default ShowAllItemsCard;
