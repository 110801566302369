import styled from 'styled-components';

import { getThemeColor, getTypographyStyle, media } from '@utils/styled';

import Typography from '@common/components/Typography';

export const Container = styled.div`
  ${({ theme }) => theme.getFluidSpacing('margin-bottom', 'scale', 16)};
  ${({ theme }) => theme.getFluidSpacing('margin-left', 'widthBased', 0, true)};
  ${({ theme }) =>
    theme.getFluidSpacing('margin-right', 'widthBased', 0, true)};

  ${media.from800up`
    margin-left: 0;
    margin-right: 0;
  `}
`;

export const Title = styled(Typography).attrs({
  customAs: 'h3',
  variant: 'h5',
})`
  ${({ theme }) => theme.getFluidSpacing('margin-bottom', 'scale', 4)};
  color: ${getThemeColor('bluegrey.60')};
  text-align: center;

  ${media.from640up`
    ${getTypographyStyle('h4')}
  `}
`;

export const ProductItem = styled.div`
  position: relative;
  overflow: hidden;
  display: inline-block;
  background-color: ${getThemeColor('bluegrey.95')};
  ${({ theme }) => theme.getFluidAttribute('width', 22.3, 43.2)};
  ${({ theme }) => theme.getFluidAttribute('height', 42.2, 59.6)};
  ${({ theme }) => theme.getFluidSpacing('margin-left', 'widthBased', 0)};

  ${media.from800up`
    margin-left: 0;
    margin-right: 2rem;
  `}
`;
