import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import {
  selectIsFetchingCategories,
  selectSubCategories,
} from '@selectors/categories';
import * as categoriesThunks from '@thunks/categories';

import { getRouteUrl } from '@utils/urls';

import LoadingIndicator from '@common/components/LoadingIndicator';
import { PRODUCTS_CATEGORY } from '@common/routes';
import { PRODUCTS_CAROUSEL_MAX_LENGTH } from '@common/constants/products';

import {
  StyledCarousel,
  CategoryImage,
  CategoryItemLink,
  CategoryTitle,
  Container,
  Title,
} from './ProductCategoriesSlider.styled';

const breakpoints = {
  1600: {
    slidesPerView: 3.5,
    spaceBetween: 20,
  },
  1024: {
    slidesPerView: 3.5,
    spaceBetween: 20,
  },
  700: {
    slidesPerView: 2.5,
    spaceBetween: 20,
  },
  565: {
    slidesPerView: 2.3,
    spaceBetween: 20,
  },
  1: {
    slidesPerView: 1.2,
    spaceBetween: 20,
  },
};

const ProductCategoriesSlider = ({ index }) => {
  const { locale } = useRouter();
  const categories = useSelector(selectSubCategories);
  const isFetchingCategories = useSelector(selectIsFetchingCategories);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (!Object.keys(categories).length) {
      dispatch(categoriesThunks.fetchCategories());
    }
  }, []);

  if (!categories.length) {
    return null;
  }

  return (
    <Container>
      <Title>
        {t('cmsBlock.productCategoriesSlider.title', 'Bekleidungskategorien')}
      </Title>

      {isFetchingCategories ? (
        <LoadingIndicator isLarge withWrapper />
      ) : (
        <StyledCarousel
          breakpoints={breakpoints}
          showBorderIndicators
          withMobileOverflow
        >
          {categories.slice(0, PRODUCTS_CAROUSEL_MAX_LENGTH).map(category => (
            <div key={category.categoryId}>
              <Link
                legacyBehavior
                href={{
                  pathname: PRODUCTS_CATEGORY,
                  query: {
                    id: category.categoryId,
                    slug: category.slug,
                  },
                }}
                as={getRouteUrl(locale, PRODUCTS_CATEGORY, {
                  id: category.categoryId,
                  slug: category.slug,
                })}
                passHref
              >
                <CategoryItemLink draggable={false}>
                  <CategoryImage
                    widths={[440, 420, 350, 300, 390, 290, 240, 370, 280]}
                    aspectRatios="4:3"
                    src={category.image}
                    forceShow={index < 2}
                    alt={category.slug}
                  />
                  <CategoryTitle>{category.title}</CategoryTitle>
                </CategoryItemLink>
              </Link>
            </div>
          ))}
        </StyledCarousel>
      )}
    </Container>
  );
};

ProductCategoriesSlider.propTypes = {
  index: PropTypes.number.isRequired,
};

export default ProductCategoriesSlider;
