import styled, { css } from 'styled-components';

import { breakpoints } from '@themes/main';

import { getThemeColor, hexToRgba, media } from '@utils/styled';
import { getFluidSizingFormula } from '@utils/sizing';

import Carousel from '@common/components/Carousel';
import Pill from '@common/components/Pill';
import Typography from '@common/components/Typography';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${getThemeColor('grey.100')};
  ${({ theme }) => theme.getFluidSpacing('margin-bottom', 'section', 3)};
  ${({ theme }) =>
    theme.getFluidSpacing(
      'margin-left',
      'widthBased',
      0,
      true,
      'safe-area-inset-left',
    )};
  ${({ theme }) =>
    theme.getFluidSpacing(
      'margin-right',
      'widthBased',
      0,
      true,
      'safe-area-inset-right',
    )};

  ${media.from880up`
    ${({ theme }) => theme.getFluidSpacing('margin-bottom', 'scale', 0)};
    margin-right: 0;
    margin-left: 0;
    border-radius: ${({ theme }) => theme.borderRadiuses['normal.2']}rem;
    flex-direction: ${({ reverseLayout }) => (reverseLayout ? 'row-reverse' : 'row')};
  `}
`;

const leftImageBorderRadius = css`
  border-top-right-radius: 0;
  border-bottom-left-radius: ${({ theme }) =>
    theme.borderRadiuses['normal.2']}rem;
  border-top-left-radius: ${({ theme }) => theme.borderRadiuses['normal.2']}rem;
`;

const rightImageBorderRadius = css`
  border-top-right-radius: ${({ theme }) =>
    theme.borderRadiuses['normal.2']}rem;
  border-bottom-right-radius: ${({ theme }) =>
    theme.borderRadiuses['normal.2']}rem;
  border-top-left-radius: 0;
`;

const heightSizingFormula = getFluidSizingFormula(
  65,
  60,
  breakpoints[0],
  breakpoints[18],
  'vh',
);

export const MediaContainer = styled.a`
  position: relative;
  overflow: hidden;
  display: flex;
  flex: 0 0 100%;
  min-height: 100%;
  background-color: ${getThemeColor('bluegrey.95')};
  line-height: 0;

  > div {
    position: absolute;
    top: 0;
    bottom: 0;
  }

  & img,
  video {
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  ${media.from880up`
    flex-basis: 48.75%;
    border-top-right-radius: ${({ theme }) => theme.borderRadiuses['normal.2']}rem;
    border-top-left-radius: ${({ theme }) => theme.borderRadiuses['normal.2']}rem;
    ${({ reverseLayout }) => (reverseLayout ? rightImageBorderRadius : leftImageBorderRadius)};
  `}

  &::before {
    content: '';
    display: block;
    ${({ theme }) =>
      theme.getFluidAttribute('height', 65, 60, heightSizingFormula, 'vh')};
    ${({ theme }) => theme.getFluidAttribute('max-height', 46, 80)};
    ${({ theme }) => theme.getFluidAttribute('min-height', 24, 40)};
  }
`;

const leftContentBorderRadius = css`
  border-width: 2px 0 2px 2px;
  border-bottom-left-radius: ${({ theme }) =>
    theme.borderRadiuses['normal.2']}rem;
  border-top-left-radius: ${({ theme }) => theme.borderRadiuses['normal.2']}rem;
`;

const rightContentBorderRadius = css`
  border-width: 2px 2px 2px 0;
  border-top-right-radius: ${({ theme }) =>
    theme.borderRadiuses['normal.2']}rem;
  border-bottom-right-radius: ${({ theme }) =>
    theme.borderRadiuses['normal.2']}rem;
`;

export const Content = styled.div`
  position: relative;
  display: flex;
  flex: 0 0 100%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  ${({ theme }) => theme.getFluidSpacing('padding-top', 'widthBased', 0)};
  border: 0 solid ${getThemeColor('bluegrey.80')};
  border-radius: 0;

  ${media.from880up`
    padding: 0;
    flex: 0 0 51.25%;
    max-width: 51.25%;
    ${({ theme }) => theme.getFluidSpacing('padding', 'widthBased', 2)};
    padding-right: 0;
    ${({ reverseLayout }) => (reverseLayout ? leftContentBorderRadius : rightContentBorderRadius)};
  `};
`;

export const Label = styled(Typography).attrs({
  variant: 'body2',
})`
  margin-bottom: 1.5rem;
  color: ${({ theme }) => hexToRgba(getThemeColor('grey.0')({ theme }), 0.5)};

  ${media.from880up`
    color: ${getThemeColor('bluegrey.60')};
  `}
`;

export const Title = styled(Typography).attrs({
  customAs: 'h2',
  variant: 'h3',
})`
  ${({ theme }) => theme.getFluidSpacing('margin-bottom', 'scale', 6)};
`;

export const Header = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 2;
  padding: 0 env(safe-area-inset-left);
  transform: translateY(-100%);

  ${media.from880up`
    position: static;
    transform: none;
  `}
  &::after {
    content: '';
    width: 100%;
    height: 0;
    padding: 0;
  }
`;

export const HeaderInner = styled.div`
  position: relative;
  z-index: 2;
  width: 100%;
  height: 100%;
  ${({ theme }) => theme.getFluidSpacing('padding-left', 'widthBased', 0)};
  ${({ theme }) => theme.getFluidSpacing('padding-right', 'widthBased', 0)};
  padding-top: 5rem;
  padding-bottom: 1px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: calc(env(safe-area-inset-right) * -1);
    bottom: -2px;
    left: calc(env(safe-area-inset-left) * -1);
    z-index: -1;
    display: block;
    background: linear-gradient(
      -180deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.4) 43%,
      rgb(255, 255, 255) 100%
    );
  }

  & > div,
  & > h3,
  & > p {
    position: relative;
    z-index: 2;
  }

  ${media.from880up`
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;

    &::before {
      content: none;
      display: none;
    }
  `}
`;

export const PricePill = styled(Pill)`
  margin-bottom: 2rem;

  > span {
    font-size: 14px;
  }

  ${media.from880down`
    color: ${getThemeColor('grey.15')};
    background: rgba(255, 255, 255, 0.2);
    border-color: rgba(0, 0, 0, 0.3);
  `}
`;

export const ProductsCarousel = styled(Carousel)`
  overflow: hidden;
  width: 100%;
  margin-right: calc(env(safe-area-inset-right) * -1);
  ${({ theme }) =>
    theme.getFluidSpacing(
      'padding-left',
      'widthBased',
      0,
      false,
      'safe-area-inset-left',
    )};
  ${({ theme }) =>
    theme.getFluidSpacing(
      'padding-right',
      'widthBased',
      0,
      false,
      'safe-area-inset-right',
    )};

  ${media.from880up`
    padding-left: 0;
    padding-right: 0;
  `}
`;

export const ProductItem = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: auto;
  height: 100%;
  background-color: ${getThemeColor('bluegrey.95')};
`;

export const ButtonsRow = styled.div`
  display: flex;
  align-self: stretch;
  ${({ theme }) => theme.getFluidSpacing('margin-left', 'widthBased', 0)};
  ${({ theme }) => theme.getFluidSpacing('margin-right', 'widthBased', 0)};

  ${media.from400up`
    align-self: center;
    margin-left: 0;
    margin-right: 0;
  `}
`;
