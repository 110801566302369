import React from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';

import { getLinkDataFromUrl } from '@utils/urls';

import { BUTTON_VARIANTS, ButtonLink } from '@common/components/Button';
import PriceText from '@common/components/PriceText';
import TeaserBlockBase, {
  Title,
  Description,
} from '@common/components/TeaserBlockBase';

const PageTeaser = ({ value, index, reverseLayout }) => {
  const { page, linkText } = value;
  const { title, image, lead, meta } = page;
  const { t } = useTranslation();
  const { htmlUrl } = meta;
  const linkData = getLinkDataFromUrl(htmlUrl);

  return (
    <TeaserBlockBase
      image={image}
      data-block-name="PageTeaser"
      index={index}
      reverseLayout={reverseLayout}
      asHero={index === 0}
      link={{
        href: linkData.urlObject,
        as: linkData.as,
      }}
    >
      <Title>
        <PriceText>{title}</PriceText>
      </Title>
      {lead && (
        <Description>
          <PriceText>{lead}</PriceText>
        </Description>
      )}

      <Link href={linkData.urlObject} passHref as={linkData.as} legacyBehavior>
        <ButtonLink variant={BUTTON_VARIANTS.LEVEL_1_GREEN}>
          {linkText || t('cmsBlock.pageTeaser.moreLinkText', 'Mehr erfahren')}
        </ButtonLink>
      </Link>
    </TeaserBlockBase>
  );
};

PageTeaser.propTypes = {
  index: PropTypes.number.isRequired,
  value: PropTypes.shape({
    linkText: PropTypes.string,
    page: PropTypes.shape({
      id: PropTypes.number.isRequired,
      image: PropTypes.shape({
        altText: PropTypes.string,
        url: PropTypes.string,
      }),
      label: PropTypes.string,
      lead: PropTypes.string,
      meta: PropTypes.shape({
        htmlUrl: PropTypes.string.isRequired,
      }),
      title: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  reverseLayout: PropTypes.bool.isRequired,
};

export default PageTeaser;
