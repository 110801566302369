import React from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import { getRouteUrl } from '@utils/urls';

import { GOODIES_DETAILS } from '@common/routes';
import { BUTTON_VARIANTS, ButtonLink } from '@common/components/Button';
import TeaserBlockBase, {
  Description,
  Label,
  Title,
} from '@common/components/TeaserBlockBase';
import PriceText from '@common/components/PriceText';

const PromotionTeaser = ({ value, index, firstAsHero, reverseLayout }) => {
  const {
    id: promotionId,
    images,
    subtitle,
    title,
    description,
    slug: promotionSlug = 'tbd',
  } = value.promotion;
  const { locale } = useRouter();
  const { t } = useTranslation();
  const shouldBeHero = firstAsHero && index === 0;

  const href = {
    pathname: GOODIES_DETAILS,
    query: {
      id: promotionId,
      slug: promotionSlug,
    },
  };
  const as = getRouteUrl(locale, GOODIES_DETAILS, {
    id: promotionId,
    slug: promotionSlug,
  });

  return (
    <TeaserBlockBase
      image={images?.[0]}
      data-block-name="PromotionTeaser"
      index={index}
      reverseLayout={reverseLayout}
      asHero={shouldBeHero}
      link={{
        href,
        as,
      }}
    >
      <Label>
        {subtitle || t('cmsBlock.promotionTeaser.offerLabel', 'VIP-Angebot')}
      </Label>
      <Title>
        <PriceText>{title}</PriceText>
      </Title>
      <Description clamp>
        {description ||
          t(
            'cmsBlock.promotionTeaser.offerDescription',
            'Jetzt neu und nur für Dich als VIP! Die VIP-Angebote! Ein Angebot zu einem einfach unschlagbaren Preis ...',
          )}
      </Description>

      <Link legacyBehavior href={href} passHref as={as}>
        <ButtonLink variant={BUTTON_VARIANTS.LEVEL_1_GREEN}>
          {t('cmsBlock.promotionTeaser.moreButtonText', 'Mehr erfahren')}
        </ButtonLink>
      </Link>
    </TeaserBlockBase>
  );
};

PromotionTeaser.propTypes = {
  firstAsHero: PropTypes.bool,
  index: PropTypes.number.isRequired,
  value: PropTypes.shape({
    promotion: PropTypes.shape({
      description: PropTypes.string,
      id: PropTypes.string.isRequired,
      images: PropTypes.arrayOf(PropTypes.shape({})),
      slug: PropTypes.string,
      subtitle: PropTypes.string,
      title: PropTypes.string.isRequired,
      validUntil: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  reverseLayout: PropTypes.bool.isRequired,
};

PromotionTeaser.defaultProps = {
  firstAsHero: false,
};

export default PromotionTeaser;
