import PropTypes from 'prop-types';
import React from 'react';

import Typography from '@common/components/Typography';
import Button, { BUTTON_VARIANTS } from '@common/components/Button';
import HTMLRenderer from '@common/components/CMSComponents/HTMLRenderer';

import { Container } from './CookieBotBlock.styled';

const CookieBotBlock = ({ value }) => {
  const { buttonText, heading, text } = value;

  return (
    <Container>
      <Typography variant="h2">{heading}</Typography>
      <Typography as="div" variant="paragraph1">
        <HTMLRenderer html={text} />
      </Typography>
      <Button
        type="Button"
        variant={BUTTON_VARIANTS.LEVEL_1_GREEN_SMALL}
        onClick={() => {
          if (typeof window === 'object' && window.Cookiebot) {
            window.Cookiebot.renew();
          }
        }}
      >
        {buttonText}
      </Button>
    </Container>
  );
};

CookieBotBlock.propTypes = {
  value: PropTypes.shape({
    buttonText: PropTypes.string,
    heading: PropTypes.string,
    text: PropTypes.string,
  }).isRequired,
};

export default CookieBotBlock;
