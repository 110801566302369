import React from 'react';
import PropTypes from 'prop-types';

import HTMLRenderer from '../HTMLRenderer';

import {
  Wrapper,
  Card,
  OptionCardWrapper,
  Heading,
  SchoolYears,
  Certification,
  Position,
  AdditionalInfoWrapper,
} from './OptionCard.styled';

const OptionCard = ({ value }) => {
  const { title, items } = value;

  return (
    <Wrapper>
      <Heading>{title}</Heading>
      <OptionCardWrapper>
        {items.map(item => (
          <Card key={item.title} singleItem={items.length === 1}>
            {item.title && <SchoolYears>{item.title}</SchoolYears>}
            {item.subTitle && <Position>{item.subTitle}</Position>}
            {item.subSubTitle && (
              <Certification>{item.subSubTitle}</Certification>
            )}
            {item.main && (
              <HTMLRenderer
                html={item.main}
                className="option-card__school-days"
              />
            )}
            {item.secondary && (
              <AdditionalInfoWrapper>
                <HTMLRenderer
                  html={item.secondary}
                  className="option-card__additional-info"
                />
              </AdditionalInfoWrapper>
            )}
          </Card>
        ))}
      </OptionCardWrapper>
    </Wrapper>
  );
};

export default OptionCard;

OptionCard.propTypes = {
  value: PropTypes.shape({
    title: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        subTitle: PropTypes.string.isRequired,
        subSubTitle: PropTypes.string.isRequired,
        main: PropTypes.string.isRequired,
        secondary: PropTypes.string,
      }),
    ).isRequired,
  }).isRequired,
};
