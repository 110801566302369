import React from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';

import { getLinkDataFromUrl } from '@utils/urls';

import { ButtonLink } from '@common/components/Button';
import HTMLRenderer from '@common/components/CMSComponents/HTMLRenderer';

import { Container } from './PageCallToAction.styled';

const PageCallToAction = ({ value }) => {
  const linkData = getLinkDataFromUrl(value.page);

  return (
    <Container>
      <HTMLRenderer html={value.text} />
      <Link
        href={linkData?.urlObject || ''}
        passHref
        as={linkData?.as}
        legacyehavior
      >
        <ButtonLink>{value.buttonText}</ButtonLink>
      </Link>
    </Container>
  );
};

PageCallToAction.propTypes = {
  value: PropTypes.shape({
    buttonText: PropTypes.string,
    page: PropTypes.string,
    text: PropTypes.string,
  }).isRequired,
};

export default PageCallToAction;
