import React from 'react';
import PropTypes from 'prop-types';

import CMSComponentsRenderer from '@common/components/CMSComponents/CMSComponentsRenderer';

const MobileFaqBlock = ({ value: { body } }) => (
  <CMSComponentsRenderer body={body} />
);

MobileFaqBlock.propTypes = {
  value: PropTypes.shape({
    body: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
};

export default MobileFaqBlock;
