import styled from 'styled-components';

import { centeredGridColumns, media, getThemeColor } from '@utils/styled';

import Carousel from '@common/components/Carousel';
import {
  CarouselWrapper,
  Controls,
} from '@common/components/Carousel/Carousel.styled';

export const Container = styled.div`
  display: block;
  grid-column: ${centeredGridColumns(12)};
  width: 100%;
  ${({ theme, noControls }) =>
    !noControls && theme.getFluidSpacing('margin-bottom', 'scale', 14)};

  ${media.from1200up`
    grid-column: ${centeredGridColumns(10)};
  `}

  ${CarouselWrapper} {
    margin-bottom: 0;
  }
`;

export const StyledSlidesCarousel = styled(Carousel)`
  ${({ theme }) =>
    theme.getFluidSpacing('margin-right', 'widthBased', 0, true)};
  ${({ theme }) => theme.getFluidSpacing('margin-left', 'widthBased', 0, true)};

  ${Controls} {
    ${({ theme }) => theme.getFluidSpacing('margin-right', 'widthBased', 0)};
    ${({ theme }) => theme.getFluidSpacing('margin-left', 'widthBased', 0)};
  }

  img {
    background-color: ${getThemeColor('bluegrey.90')};
  }

  ${media.from640up`
    margin-right: 0;
    margin-left: 0;

    ${Controls} {
      margin-right: 0;
      margin-left: 0;
    }
  `}
`;
