import { useTranslation } from 'next-i18next';
import PropTypes from 'prop-types';
import React from 'react';

import { OpeningHoursRow } from '@routes/Store/Store.styled';

import { getCurrentDayNameShort } from '@utils/shop';
import { formatPhoneNumber } from '@utils/formatNumber';
import { convertLinebreaksToControlledBrs } from '@utils/convertLinebreaksToBr';

import * as Icons from '@common/components/Icons';

import {
  AdditionalInfo,
  AddressButton,
  AddressOpeningGrid,
  ContentColumn,
  DescriptionTitle,
  OpeningDay,
  OpeningDaysHoursGrid,
  OpeningHoursContainer,
  Phone,
  StoreDescription,
  Street,
  ZipCodeCity,
} from './StoreInfo.styled';

function OpeningHours({ businessHours, isCurrentDay }) {
  const { t } = useTranslation('common');

  if (businessHours.length === 0) {
    return (
      <OpeningHoursRow isCurrentDay={isCurrentDay}>
        {t('storeInfo.businessHours.closed', 'Geschlossen')}
      </OpeningHoursRow>
    );
  }

  return businessHours.map(({ openTime, closeTime }) => {
    const [fromHours, fromMinutes] = openTime.split(':');
    const [toHours, toMinutes] = closeTime.split(':');

    return (
      <OpeningHoursRow key={openTime + closeTime} isCurrentDay={isCurrentDay}>
        {`${fromHours}:${fromMinutes} - ${toHours}:${toMinutes}`}
      </OpeningHoursRow>
    );
  });
}

const StoreInfo = ({
  street,
  houseNr,
  zipCode,
  phone,
  city,
  mapLink,
  description,
  businessHours,
  additionalLocationInfo,
}) => {
  const { t } = useTranslation();

  const currentDayNameShort = getCurrentDayNameShort();

  return (
    <AddressOpeningGrid>
      <ContentColumn data-testid="storeInformation">
        <DescriptionTitle>
          {t('storeInfo.address.title', 'Adresse')}
        </DescriptionTitle>
        {additionalLocationInfo && (
          <AdditionalInfo>{additionalLocationInfo}</AdditionalInfo>
        )}
        <Street>
          {street} {houseNr}
        </Street>
        <ZipCodeCity>
          {zipCode} {city}
        </ZipCodeCity>
        {phone && (
          <Phone>
            <a href={`tel:${phone}`}>{formatPhoneNumber(phone)}</a>
          </Phone>
        )}
        <AddressButton
          iconRight={<Icons.ArrowExternal />}
          href={mapLink}
          rel="noopener noreferrer"
          target="_blank"
        >
          {t('storeInfo.address.button', 'Wegbeschreibung')}
        </AddressButton>
        {description && (
          <StoreDescription
            dangerouslySetInnerHTML={{
              __html: convertLinebreaksToControlledBrs(description, 2),
            }}
          />
        )}
      </ContentColumn>
      <ContentColumn data-testid="storeOpeningHours">
        <DescriptionTitle>
          {t('storeInfo.businessHours', 'Öffnungszeiten')}
        </DescriptionTitle>
        <OpeningDaysHoursGrid>
          <OpeningDay isCurrentDay={currentDayNameShort === 'mon'}>
            {t('monday', 'Montag')}
          </OpeningDay>
          <OpeningHoursContainer>
            <OpeningHours
              businessHours={businessHours.mon}
              isCurrentDay={currentDayNameShort === 'mon'}
            />
          </OpeningHoursContainer>
          <OpeningDay isCurrentDay={currentDayNameShort === 'tue'}>
            {t('tuesday', 'Dienstag')}
          </OpeningDay>
          <OpeningHoursContainer>
            <OpeningHours
              businessHours={businessHours.tue}
              isCurrentDay={currentDayNameShort === 'tue'}
            />
          </OpeningHoursContainer>
          <OpeningDay isCurrentDay={currentDayNameShort === 'wed'}>
            {t('wednesday', 'Mittwoch')}
          </OpeningDay>
          <OpeningHoursContainer>
            <OpeningHours
              businessHours={businessHours.wed}
              isCurrentDay={currentDayNameShort === 'wed'}
            />
          </OpeningHoursContainer>
          <OpeningDay isCurrentDay={currentDayNameShort === 'thu'}>
            {t('thursday', 'Donnerstag')}
          </OpeningDay>
          <OpeningHoursContainer>
            <OpeningHours
              businessHours={businessHours.thu}
              isCurrentDay={currentDayNameShort === 'thu'}
            />
          </OpeningHoursContainer>
          <OpeningDay isCurrentDay={currentDayNameShort === 'fri'}>
            {t('friday', 'Freitag')}
          </OpeningDay>
          <OpeningHoursContainer>
            <OpeningHours
              businessHours={businessHours.fri}
              isCurrentDay={currentDayNameShort === 'fri'}
            />
          </OpeningHoursContainer>
          <OpeningDay isCurrentDay={currentDayNameShort === 'sat'}>
            {t('saturday', 'Samstag')}
          </OpeningDay>
          <OpeningHoursContainer>
            <OpeningHours
              businessHours={businessHours.sat}
              isCurrentDay={currentDayNameShort === 'sat'}
            />
          </OpeningHoursContainer>
          <OpeningDay isCurrentDay={currentDayNameShort === 'sun'}>
            {t('sunday', 'Sonntag')}
          </OpeningDay>
          <OpeningHoursContainer>
            <OpeningHours
              businessHours={businessHours.sun}
              isCurrentDay={currentDayNameShort === 'sun'}
            />
          </OpeningHoursContainer>
        </OpeningDaysHoursGrid>
      </ContentColumn>
    </AddressOpeningGrid>
  );
};

export const businessHoursPropTypes = PropTypes.shape({
  fri: PropTypes.arrayOf(
    PropTypes.shape({
      closeTime: PropTypes.string,
      openTime: PropTypes.string,
    }),
  ),
  mon: PropTypes.arrayOf(
    PropTypes.shape({
      closeTime: PropTypes.string,
      openTime: PropTypes.string,
    }),
  ),
  sat: PropTypes.arrayOf(
    PropTypes.shape({
      closeTime: PropTypes.string,
      openTime: PropTypes.string,
    }),
  ),
  sun: PropTypes.arrayOf(
    PropTypes.shape({
      closeTime: PropTypes.string,
      openTime: PropTypes.string,
    }),
  ),
  thu: PropTypes.arrayOf(
    PropTypes.shape({
      closeTime: PropTypes.string,
      openTime: PropTypes.string,
    }),
  ),
  tue: PropTypes.arrayOf(
    PropTypes.shape({
      closeTime: PropTypes.string,
      openTime: PropTypes.string,
    }),
  ),
  wed: PropTypes.arrayOf(
    PropTypes.shape({
      closeTime: PropTypes.string,
      openTime: PropTypes.string,
    }),
  ),
});

export const storeInfoPropTypes = {
  businessHours: businessHoursPropTypes.isRequired,
  city: PropTypes.string,
  description: PropTypes.string,
  houseNr: PropTypes.string,
  mapLink: PropTypes.string,
  phone: PropTypes.string,
  street: PropTypes.string,
  zipCode: PropTypes.string,
  additionalLocationInfo: PropTypes.string,
};

OpeningHours.propTypes = {
  businessHours: PropTypes.arrayOf(
    PropTypes.shape({
      closeTime: PropTypes.string,
      openTime: PropTypes.string,
    }),
  ),
  isCurrentDay: PropTypes.bool,
};

OpeningHours.defaultProps = {
  businessHours: [],
  isCurrentDay: false,
};

StoreInfo.propTypes = storeInfoPropTypes;

StoreInfo.defaultProps = {
  city: '',
  description: '',
  houseNr: '',
  mapLink: '',
  phone: '',
  street: '',
  zipCode: '',
  additionalLocationInfo: '',
};

export default StoreInfo;
