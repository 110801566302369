import styled from 'styled-components';

import { getThemeColor, getTypographyStyle, media } from '@utils/styled';

import Typography from '@common/components/Typography';

export const Container = styled.div`
  position: relative;
  ${({ theme }) => theme.getFluidSpacing('margin-bottom', 'section', 3)};
  ${({ theme }) =>
    theme.getFluidSpacing(
      'margin-left',
      'widthBased',
      0,
      true,
      'safe-area-inset-left',
    )};
  ${({ theme }) =>
    theme.getFluidSpacing(
      'margin-right',
      'widthBased',
      0,
      true,
      'safe-area-inset-right',
    )};

  ${media.from880down`
    ${({ theme }) => theme.getFluidSpacing('margin-bottom', 'scale', 0)};
    &::before {
      content: '';
      position: absolute;
      z-index: -2;
      width: 100%;
      height: 15rem;
      background: ${getThemeColor('brand.bluegreyGradient')};
    }
  `}
`;

export const Title = styled(Typography).attrs({
  customAs: 'h2',
  variant: 'h5',
})`
  ${({ theme }) => theme.getFluidSpacing('margin-bottom', 'scale', 4)};
  color: ${getThemeColor('bluegrey.60')};
  text-align: center;

  ${media.from640up`
    ${getTypographyStyle('h4')}
  `}

  ${media.from880down`
    padding-top: 3rem;
  `}
`;

export const CarouselContainer = styled.div`
  overflow: hidden;
  ${({ theme }) =>
    theme.getFluidSpacing(
      'padding-left',
      'widthBased',
      0,
      false,
      'safe-area-inset-left',
    )};
  ${({ theme }) =>
    theme.getFluidSpacing(
      'padding-right',
      'widthBased',
      0,
      false,
      'safe-area-inset-right',
    )};
`;

export const ProductItem = styled.div`
  position: relative;
  overflow: hidden;
  width: auto;
  height: 100%;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ${media.from880down`
    ${({ theme }) => theme.getFluidSpacing('margin-left', 'widthBased', 0)};
    ${({ theme }) => theme.getFluidSpacing('margin-right', 'widthBased', 0)};
  `}
`;
