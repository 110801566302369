import PropTypes from 'prop-types';
import React from 'react';

import LazyImg from '@common/components/LazyImg/LazyImg';

import { Container } from './ImageBlock.styled';

const ImageBlock = ({ value: { url, altText, width, height } = {} }) => (
  <Container>
    <LazyImg
      src={url}
      alt={altText || ''}
      aspectRatios={`${+(width / height).toFixed(3)}:1`}
      widths={[1330, 1210, 1030, 1080, 920, 720, 570, 480, 375]}
    />
  </Container>
);

ImageBlock.propTypes = {
  value: PropTypes.shape({
    altText: PropTypes.string,
    url: PropTypes.string.isRequired,
  }).isRequired,
};

export default ImageBlock;
