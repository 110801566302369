import React from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';

import { PREFIXED_INTERNAL_LINK_DOMAINS } from '@utils/domains';
import { getLinkDataFromUrl } from '@utils/urls';

import { BUTTON_VARIANTS, ButtonLink } from '@common/components/Button';

import { ButtonWrapper } from './ButtonBlock.styled';

const ButtonBlock = ({ value: { buttonText, url } }) => {
  const linkURL = PREFIXED_INTERNAL_LINK_DOMAINS.some(domain =>
    url?.includes(domain),
  )
    ? url
    : null;
  const linkData = linkURL && getLinkDataFromUrl(linkURL);

  return (
    <ButtonWrapper>
      {linkData ? (
        <Link
          href={linkData.urlObject}
          as={linkData.as}
          passHref
          legacyBehavior
        >
          <ButtonLink type="button" variant={BUTTON_VARIANTS.LEVEL_1_GREEN}>
            {buttonText}
          </ButtonLink>
        </Link>
      ) : (
        <ButtonLink
          type="button"
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          isExternal
          variant={BUTTON_VARIANTS.LEVEL_1_GREEN}
        >
          {buttonText}
        </ButtonLink>
      )}
    </ButtonWrapper>
  );
};

ButtonBlock.propTypes = {
  value: PropTypes.shape({
    buttonText: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
};

export default ButtonBlock;
