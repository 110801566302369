import styled from 'styled-components';

import { centeredGridColumns, getThemeColor, media } from '@utils/styled';

export const Container = styled.div`
  grid-column: ${centeredGridColumns(12)};
  ${({ theme }) => theme.getFluidSpacing('margin-bottom', 'scale', 1)};
  ${({ theme }) =>
    theme.getFluidSpacing('margin-right', 'widthBased', 0, true)};
  ${({ theme }) => theme.getFluidSpacing('margin-left', 'widthBased', 0, true)};
  background-color: ${getThemeColor('bluegrey.95')};

  ${media.from640up`
    margin-left: 0;
    margin-right: 0;
  `}

  ${media.from1200up`
    grid-column: ${centeredGridColumns(10)};
  `}
`;
