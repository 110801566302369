import React from 'react';
import PropTypes from 'prop-types';

import LazyImg from '@common/components/LazyImg/LazyImg';
import Hr from '@common/components/Hr';

import { Container, StyledSlidesCarousel } from './GalleryBlock.styled';

const breakpoints = {
  1: {
    slidesPerView: 1,
    spaceBetween: 0,
  },
};

const GalleryBlock = ({ value }) => {
  const { items, aspectRatio } = value;

  if (!items?.length) return null;

  const noControls = items.length <= 1;

  return (
    <Container noControls={noControls}>
      <StyledSlidesCarousel
        breakpoints={breakpoints}
        infinite={!noControls}
        withCounter
        hideNav={noControls}
        hideScrollbar
      >
        {items.map(({ image }) => (
          <LazyImg
            key={image?.url}
            widths={[1330, 1210, 1030, 1080, 920, 720, 570, 480, 375]}
            aspectRatios={aspectRatio}
            src={image?.url}
            alt={image?.altText}
            draggable={false}
          />
        ))}
      </StyledSlidesCarousel>
      <Hr spacings={['equal', 4]} />
    </Container>
  );
};

GalleryBlock.propTypes = {
  value: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        caption: PropTypes.string,
        image: PropTypes.shape({
          altText: PropTypes.string,
          height: PropTypes.number.isRequired,
          url: PropTypes.string.isRequired,
          width: PropTypes.number.isRequired,
        }),
      }),
    ).isRequired,
    aspectRatio: PropTypes.string.isRequired,
  }).isRequired,
};

export default GalleryBlock;
