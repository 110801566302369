import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';

import { getRouteUrl } from '@utils/urls';

import { ButtonLink } from '@common/components/Button';
import LoadingIndicator from '@common/components/LoadingIndicator';

import {
  Title,
  Wrapper,
  CarouselContainer,
  LoaderWrapper,
  VoucherWrapper,
  StyledCarousel,
} from './VouchersCarouselBase.styled';
import { MAX_CAROUSEL_ITEMS } from './VouchersCarouselBase.helpers';
import { useShouldRenderCarousel } from './VouchersCarouselBase.hooks';

export const breakpoints = {
  1601: {
    slidesPerView: 3.75,
    spaceBetween: 20,
  },
  1201: {
    slidesPerView: 3.65,
    spaceBetween: 20,
  },
  881: {
    slidesPerView: 2.5,
    spaceBetween: 20,
  },
  501: {
    slidesPerView: 1.5,
    spaceBetween: 20,
  },
  1: {
    slidesPerView: 1.25,
    spaceBetween: 20,
  },
};

const VouchersCarousel = ({
  children,
  shouldRenderCarousel,
  ...carouselProps
}) => {
  if (!shouldRenderCarousel) {
    return <React.Fragment>{children}</React.Fragment>;
  }

  return (
    <StyledCarousel breakpoints={breakpoints} {...carouselProps}>
      {children}
    </StyledCarousel>
  );
};

const VouchersCarouselBase = ({
  items,
  title,
  linkText,
  linkHref,
  isLoading,
  seeMoreElement,
}) => {
  const {
    i18n: { language },
  } = useTranslation();

  const shouldRenderCarousel = useShouldRenderCarousel(items.length);

  const results = useMemo(() => {
    if (items.length <= MAX_CAROUSEL_ITEMS) {
      return items;
    }

    const slicedItems = items.slice(0, MAX_CAROUSEL_ITEMS);

    return [...slicedItems, seeMoreElement];
  }, [items, seeMoreElement]);

  return (
    <Wrapper>
      <Title>{title}</Title>
      <CarouselContainer
        shouldRenderCarousel={shouldRenderCarousel}
        isLoading={isLoading}
      >
        {!isLoading ? (
          <VouchersCarousel
            shouldRenderCarousel={shouldRenderCarousel}
            withDesktopOverflow
          >
            {results.map((item, index) => (
              <VoucherWrapper
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                shouldRenderCarousel={shouldRenderCarousel}
              >
                {item}
              </VoucherWrapper>
            ))}
          </VouchersCarousel>
        ) : (
          <LoaderWrapper>
            <LoadingIndicator isLarge />
          </LoaderWrapper>
        )}
      </CarouselContainer>

      <Link
        legacyBehavior
        href={linkHref}
        passHref
        as={getRouteUrl(language, linkHref)}
      >
        <ButtonLink>{linkText}</ButtonLink>
      </Link>
    </Wrapper>
  );
};

VouchersCarouselBase.propTypes = {
  items: PropTypes.arrayOf(PropTypes.node).isRequired,
  isLoading: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired,
  linkHref: PropTypes.string.isRequired,
  seeMoreElement: PropTypes.node.isRequired,
};

VouchersCarousel.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  shouldRenderCarousel: PropTypes.bool.isRequired,
};

export default VouchersCarouselBase;
