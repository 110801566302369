import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { centeredGridColumns, media } from '@utils/styled';

const Container = styled.div`
  grid-column: ${centeredGridColumns(12)};
  ${({ theme }) => theme.getFluidSpacing('margin-bottom', 'section', 3)};
  border: 1px solid #ddd;
  padding: 1em;
  background-color: #f3f3f3;

  ${({ isError }) =>
    isError &&
    `
    background-color: #ffbfbf;
    border-color: #ff8080;
  `}

  ${media.from880up`
    ${({ theme }) => theme.getFluidSpacing('margin-bottom', 'scale', 0)};
  `}
`;

const ErrorBlock = ({ type, id, index, reason, blockValue, isError }) => {
  const [showRest, setShowRest] = useState(false);

  return (
    <Container isError={isError}>
      <p>
        {reason}:<strong>{type}</strong>
      </p>

      <p>
        id:
        {id}
      </p>

      <p>
        index:
        {index}
      </p>

      {showRest ? (
        <textarea value={JSON.stringify(blockValue, null, 4)} readOnly />
      ) : (
        <button type="button" onClick={() => setShowRest(true)}>
          show the rest props
        </button>
      )}
    </Container>
  );
};

ErrorBlock.propTypes = {
  id: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  reason: PropTypes.string,
  blockValue: PropTypes.shape({}).isRequired,
  isError: PropTypes.bool.isRequired,
};

ErrorBlock.defaultProps = {
  reason: 'Unsupported block type',
};

export default ErrorBlock;
