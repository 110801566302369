import React from 'react';
import { useSelector } from 'react-redux';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import PropTypes from 'prop-types';

import { selectCategoryDetails } from '@selectors/categories';

import { getRouteUrl } from '@utils/urls';

import { BUTTON_VARIANTS, ButtonLink } from '@common/components/Button';
import ShowAllItemsCard from '@common/components/ShowAllItemsCard';
import Product from '@common/containers/Product';
import {
  PRODUCTS_ALL,
  PRODUCTS_CATEGORY,
  PRODUCTS_NEW,
  PRODUCTS_OFFER,
  PRODUCTS_SALE,
} from '@common/routes';
import {
  PRODUCTS_OFFER_CAROUSEL,
  PRODUCTS_NEW_ARRIVALS_CAROUSEL,
  PRODUCTS_BY_CATEGORIES_CAROUSEL,
  TRENDING_PRODUCTS_CAROUSEL,
} from '@common/constants/cms';
import {
  PRODUCTS_CAROUSEL_MAX_LENGTH,
  PRODUCTS_CAROUSEL_MIN_LENGTH,
} from '@common/constants/products';
import { OFFER_TYPES } from '@common/constants/filters';
import Carousel from '@common/components/Carousel';

import {
  ButtonContainer,
  CarouselContainer,
  Container,
  ProductItem,
  Title,
} from './ProductsCarousel.styled';

const offerTypeToLink = {
  [OFFER_TYPES.OFFER]: PRODUCTS_OFFER,
  [OFFER_TYPES.SALE]: PRODUCTS_SALE,
};

const typeToLinkHref = {
  [PRODUCTS_OFFER_CAROUSEL]: ({ offerType }) => offerTypeToLink[offerType],
  [PRODUCTS_NEW_ARRIVALS_CAROUSEL]: () => ({ pathname: PRODUCTS_NEW }),
  [TRENDING_PRODUCTS_CAROUSEL]: () => null,
  [PRODUCTS_BY_CATEGORIES_CAROUSEL]: ({ slug, categoryId }) => ({
    pathname: PRODUCTS_CATEGORY,
    query: {
      slug,
      id: categoryId,
    },
  }),
};

const typeToLinkAs = {
  [PRODUCTS_OFFER_CAROUSEL]: ({ locale, offerType }) =>
    offerType && getRouteUrl(locale, offerTypeToLink[offerType]),
  [PRODUCTS_NEW_ARRIVALS_CAROUSEL]: ({ locale }) =>
    getRouteUrl(locale, PRODUCTS_NEW),
  [TRENDING_PRODUCTS_CAROUSEL]: ({ locale }) =>
    getRouteUrl(locale, PRODUCTS_ALL),
  [PRODUCTS_BY_CATEGORIES_CAROUSEL]: ({ locale, slug, categoryId }) =>
    getRouteUrl(locale, PRODUCTS_CATEGORY, {
      slug,
      id: categoryId,
    }),
};

const breakpoints = {
  1024: {
    slidesPerView: 3.5,
    spaceBetween: 20,
  },
  881: {
    slidesPerView: 2.25,
    spaceBetween: 20,
  },
  501: {
    slidesPerView: 2.25,
    spaceBetween: 20,
  },
  1: {
    slidesPerView: 1.25,
    spaceBetween: 20,
  },
};

const ProductsByCategoriesCarousel = ({
  value: { heading, categoryId, offerType },
  data: products,
  type,
}) => {
  const { locale } = useRouter();
  const { t } = useTranslation();
  const category = useSelector(selectCategoryDetails(categoryId));

  const href = typeToLinkHref[type]({
    offerType,
    slug: category?.slug,
    categoryId,
  });
  const as = typeToLinkAs[type]({
    locale,
    offerType,
    slug: category?.slug,
    categoryId,
  });

  if (products.length < PRODUCTS_CAROUSEL_MIN_LENGTH) {
    return null;
  }

  const carouselItems = products
    .slice(0, PRODUCTS_CAROUSEL_MAX_LENGTH)
    .map(product => (
      <ProductItem data-testid="productItem" key={product.productColorId}>
        <Product {...product} />
      </ProductItem>
    ));

  if (products.length > PRODUCTS_CAROUSEL_MAX_LENGTH && href && as) {
    carouselItems.push(
      <ShowAllItemsCard
        key="showAllItem"
        linkHref={href}
        linkAs={as}
        buttonText={t('cmsBlock.favorites.showAllButton', 'Alle ansehen')}
      />,
    );
  }

  return (
    <Container data-testid="productsCarouselBlock">
      <Title>{heading}</Title>

      <CarouselContainer>
        <Carousel
          breakpoints={breakpoints}
          showBorderIndicators
          withMobileOverflow
          slideCursor="pointer"
        >
          {carouselItems}
        </Carousel>
      </CarouselContainer>

      {href && as && (
        <ButtonContainer>
          <Link legacyBehavior href={href} passHref as={as}>
            <ButtonLink variant={BUTTON_VARIANTS.LEVEL_1_GREEN}>
              {t('cmsBlock.productsCarousel.showAllButton', 'Alle ansehen')}
            </ButtonLink>
          </Link>
        </ButtonContainer>
      )}
    </Container>
  );
};

ProductsByCategoriesCarousel.defaultProps = {
  value: {
    categoryId: '',
    offerType: '',
  },
};

ProductsByCategoriesCarousel.propTypes = {
  value: PropTypes.shape({
    categoryId: PropTypes.string,
    offerType: PropTypes.oneOf([
      OFFER_TYPES.OFFER,
      OFFER_TYPES.SALE,
      OFFER_TYPES.VIPOFFER,
    ]),
    heading: PropTypes.string.isRequired,
  }),
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  type: PropTypes.oneOf([
    PRODUCTS_OFFER_CAROUSEL,
    PRODUCTS_NEW_ARRIVALS_CAROUSEL,
    PRODUCTS_BY_CATEGORIES_CAROUSEL,
  ]).isRequired,
};

export default ProductsByCategoriesCarousel;
