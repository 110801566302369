import PropTypes from 'prop-types';
import React from 'react';

import { numberToSup } from '@utils/formatNumber';

import PriceText from '@common/components/PriceText';
import { DotsRow, Wrapper } from '@common/components/Voucher/Voucher.styled';

import { PriceTag } from './Bon.styled';

export const GiftCardTop = ({
  children,
  isButton,
  priceTag,
  onClick,
  dataTestid,
}) => (
  <Wrapper
    as={isButton ? 'button' : 'div'}
    isButton={isButton}
    onClick={onClick}
    data-testid={dataTestid}
  >
    {priceTag !== 0 && (
      <PriceTag>
        CHF <PriceText>{numberToSup(priceTag)}</PriceText>
      </PriceTag>
    )}
    {children}
    <DotsRow isAtTop={false} />
  </Wrapper>
);

GiftCardTop.propTypes = {
  children: PropTypes.node,
  isButton: PropTypes.bool,
  onClick: PropTypes.func,
  priceTag: PropTypes.number,
  dataTestid: PropTypes.string,
};

GiftCardTop.defaultProps = {
  children: undefined,
  isButton: false,
  onClick: undefined,
  priceTag: 0,
  dataTestid: '',
};
