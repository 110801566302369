import React from 'react';
import PropTypes from 'prop-types';

import LazyImg from '@common/components/LazyImg';
import HTMLRenderer from '../HTMLRenderer';

import { Container, Description } from './GalleryBlockWithText.styled';

const aspectRatioMap = {
  0: '3:2',
  1: '1:1.7',
  2: '1:1',
};

const widthsMap = {
  0: [908, 855, 820, 720, 620, 520, 420, 320, 295],
  1: [493, 420, 410, 380, 350, 323, 255, 223, 123],
  2: [632, 570, 520, 500, 450, 350, 225, 200, 185],
};

const DescriptionRenderer = ({ text }) => {
  if (!text) {
    return null;
  }

  return (
    <Description>
      <HTMLRenderer className="blockText" html={text} />
    </Description>
  );
};

DescriptionRenderer.propTypes = {
  text: PropTypes.string.isRequired,
};

const GalleryBlockWithText = ({ value, reverseLayout }) => (
  <Container
    data-testid="galleryBlockWithText"
    shouldFlipImages={reverseLayout}
  >
    {Object.entries(value).map(([key, val], i) => (
      <React.Fragment key={key !== 'text' ? val.url : val}>
        {key !== 'text' ? (
          <LazyImg
            widths={widthsMap[i]}
            aspectRatios={aspectRatioMap[i]}
            src={val.url}
            alt={val.altText}
          />
        ) : (
          <DescriptionRenderer text={val} />
        )}
      </React.Fragment>
    ))}
  </Container>
);

GalleryBlockWithText.propTypes = {
  reverseLayout: PropTypes.bool.isRequired,
  value: PropTypes.shape({
    image1: PropTypes.shape({}),
    image2: PropTypes.shape({}),
    image3: PropTypes.shape({}),
    text: PropTypes.string,
  }).isRequired,
};

export default GalleryBlockWithText;
