import React, { createContext, useContext, useEffect, useRef } from 'react';
import throttle from 'lodash/throttle';
import PropTypes from 'prop-types';

const LookbookSliderContext = createContext({
  refs: [],
});

export const useLookbookSliderContext = () => useContext(LookbookSliderContext);

const removeNoElRef = ref => ref?.el !== null;

export const LookbookSliderContextProvider = ({ children, onScrolledTo }) => {
  const refs = useRef([]);

  useEffect(() => {
    const handleScroll = throttle(() => {
      const middle = window.scrollY + window.innerHeight / 2;
      // eslint-disable-next-line consistent-return, array-callback-return
      const distances = refs.current.filter(removeNoElRef).map(ref => {
        if (!ref?.el) {
          return null;
        }

        return Math.abs(ref.el.offsetTop + ref.el.offsetHeight / 2 - middle);
      });

      const closestIndex = distances.indexOf(Math.min(...distances));

      onScrolledTo(refs?.current?.[closestIndex]?.slideIndex);
    }, 300);

    window.addEventListener('scroll', handleScroll, {
      capture: false,
      passive: true,
    });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [refs, onScrolledTo]);

  return (
    <LookbookSliderContext.Provider value={{ refs }}>
      {children}
    </LookbookSliderContext.Provider>
  );
};

LookbookSliderContextProvider.propTypes = {
  onScrolledTo: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};
