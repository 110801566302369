import Link from 'next/link';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  selectIsFetching,
  selectProductColorVariations,
} from '@selectors/products';
import * as productsThunks from '@thunks/products';

import { PREFIXED_INTERNAL_LINK_DOMAINS } from '@utils/domains';
import { getLinkDataFromUrl } from '@utils/urls';

import CrossFadeImages from '@common/components/CrossFadeImages';
import { ButtonLink, BUTTON_VARIANTS } from '@common/components/Button';

import {
  GlobalStyle,
  Container,
  ImageContainer,
  ResultsContainer,
  StickyContainer,
  ButonWrapper,
} from './LookbookProductsListSlider.styled';
import SingleLookbookSlider from './SingleLookbookSlider';
import { LookbookSliderContextProvider } from './lookbookSliderContext';

const filterContainers = (containers, prodcuts) => {
  const productIds = prodcuts.map(p => p.productColorId);

  return containers.filter(container =>
    container.items.some(item => productIds.includes(item.productColorId)),
  );
};

const flatContainersProductsIds = containers =>
  containers
    .map(container => container.items.map(i => i.productColorId))
    .flat();

const LookbookProductsListSlider = ({ value }) => {
  const resultsContainerRef = useRef();
  const { items: containers, buttonText, url } = value;
  const containersProductsIds = flatContainersProductsIds(containers);
  const products = useSelector(
    selectProductColorVariations([...new Set(containersProductsIds)]),
  );
  const filteredContainers = filterContainers(containers, products);
  const mainImages = filteredContainers.map(({ mainImage }) => mainImage?.url);
  const isFetchingProducts = useSelector(selectIsFetching);
  const dispatch = useDispatch();
  const [visibleMainItem, setVisibleMainItem] = useState(0);

  const crossFadeContainerRef = useRef();
  const handleItemVisible = index => {
    setVisibleMainItem(index);
  };

  useEffect(() => {
    if (products.length < containersProductsIds.length) {
      dispatch(productsThunks.fetchProductsByColorIds(containersProductsIds));
    }
  }, []);

  const linkURL = PREFIXED_INTERNAL_LINK_DOMAINS.some(domain =>
    url?.includes(domain),
  )
    ? url
    : null;
  const linkData = linkURL && getLinkDataFromUrl(linkURL);

  const link = linkData ? (
    <Link legacyBehavior href={linkData.urlObject} as={linkData.as} passHref>
      <ButtonLink variant={BUTTON_VARIANTS.LEVEL_1_GREEN}>
        {buttonText}
      </ButtonLink>
    </Link>
  ) : (
    <ButtonLink
      variant={BUTTON_VARIANTS.LEVEL_1_GREEN}
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      isExternal
    >
      {buttonText}
    </ButtonLink>
  );

  return (
    <React.Fragment>
      <GlobalStyle />
      <Container>
        <ImageContainer>
          <StickyContainer ref={crossFadeContainerRef}>
            <CrossFadeImages
              widths={[1050, 950, 810, 710, 600, 800, 640, 480, 375]}
              imagesUrls={mainImages}
              activeIndex={visibleMainItem}
            />
          </StickyContainer>
        </ImageContainer>
        <LookbookSliderContextProvider
          onScrolledTo={handleItemVisible}
          container={resultsContainerRef}
        >
          <ResultsContainer ref={resultsContainerRef}>
            {filteredContainers.map((slider, sliderIndex, arr) => {
              const { items } = slider;
              const containerProductIds = items.map(i => i.productColorId);
              const containerProducts = products.filter(product =>
                containerProductIds.includes(product.productColorId),
              );
              const previousContainersProductsIds = arr
                .slice(0, sliderIndex)
                .map(s =>
                  s.items
                    .map(i => i.productColorId)
                    .filter(id => products.some(p => p.productColorId === id)),
                )
                .flat().length;

              return (
                <SingleLookbookSlider
                  isFetchingProducts={isFetchingProducts}
                  key={slider.mainImage.title}
                  mainImages={mainImages}
                  products={containerProducts}
                  sliderIndex={sliderIndex}
                  productsIndexStartFrom={previousContainersProductsIds}
                  image={mainImages[sliderIndex]}
                />
              );
            })}
          </ResultsContainer>
        </LookbookSliderContextProvider>
        {buttonText && url && buttonText?.length > 0 && (
          <ButonWrapper>{link}</ButonWrapper>
        )}
      </Container>
    </React.Fragment>
  );
};

LookbookProductsListSlider.propTypes = {
  value: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        productColorId: PropTypes.string,
      }),
    ),
    mainImage: PropTypes.shape({
      altText: PropTypes.string,
      height: PropTypes.number.isRequired,
      url: PropTypes.string.isRequired,
      width: PropTypes.number.isRequired,
    }),
    buttonText: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
};

export default LookbookProductsListSlider;
