import styled from 'styled-components';

import { getThemeColor, getTypographyStyle, media } from '@utils/styled';

import Typography from '@common/components/Typography';
import Carousel from '@common/components/Carousel';

export const Container = styled.div`
  position: relative;
  ${({ theme }) => theme.getFluidSpacing('margin-bottom', 'scale', 16)}
  ${({ theme }) => theme.getFluidSpacing('margin-left', 'widthBased', 0, true)};
  ${({ theme }) =>
    theme.getFluidSpacing('margin-right', 'widthBased', 0, true)};

  ${media.from880down`
    &::before {
      content: '';
      position: absolute;
      z-index: -2;
      width: 100%;
      height: 15rem;
      background: ${getThemeColor('brand.bluegreyGradient')};
    }
  `}
`;

export const Title = styled(Typography).attrs({
  customAs: 'h2',
  variant: 'h5',
})`
  ${({ theme }) => theme.getFluidSpacing('margin-bottom', 'scale', 4)};
  color: ${getThemeColor('bluegrey.60')};
  text-align: center;

  ${media.from640up`
    ${getTypographyStyle('h4')}
  `}

  ${media.from880down`
    padding-top: 3rem;
  `}
`;

export const FavoritesCarousel = styled(Carousel)`
  ${({ theme }) => theme.getFluidSpacing('padding-left', 'widthBased', 0)};
  ${({ theme }) => theme.getFluidSpacing('padding-right', 'widthBased', 0)};

  ${media.from800up`
    padding-left: 0;
    padding-right: 0;
  `}
`;

export const CarouselContainer = styled.div`
  overflow: hidden;
  ${media.from800up`
    ${({ theme }) => theme.getFluidSpacing('margin-left', 'widthBased', 0)};
    ${({ theme }) => theme.getFluidSpacing('margin-right', 'widthBased', 0)};
    ${({ theme }) => theme.getFluidSpacing('margin-bottom', 'widthBased', 2)};
  `}
`;

export const ProductItem = styled.div`
  position: relative;
  overflow: hidden;
  width: auto;
  height: 100%;
  background-color: ${getThemeColor('bluegrey.95')};
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ${media.from880down`
    ${({ theme }) => theme.getFluidSpacing('margin-left', 'widthBased', 0)};
    ${({ theme }) => theme.getFluidSpacing('margin-right', 'widthBased', 0)};
  `}
`;
