import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import getConfig from 'next/config';
import Script from 'next/script';
import { useRouter } from 'next/router';

import { getLocale } from '@utils/locale';

import Typography from '@common/components/Typography';
import HTMLRenderer from '@common/components/CMSComponents/HTMLRenderer';

import { Container } from './CookieBotDeclarationBlock.styled';

const { COOKIEBOT_ID } = getConfig().publicRuntimeConfig;

const CookieBotDeclarationBlock = ({ value }) => {
  const { locale } = useRouter();
  const { heading, text } = value;

  useEffect(() => {
    if (window.CookiebotCookieDeclaration) {
      window.CookiebotCookieDeclaration.init();
    }
  }, []);

  return (
    <Container>
      <Typography variant="h2">{heading}</Typography>
      <Typography as="div" variant="paragraph1">
        <HTMLRenderer html={text} />
      </Typography>
      <Script
        src={`https://consent.cookiebot.com/${COOKIEBOT_ID}/cd.js`}
        type="text/javascript"
        data-culture={getLocale(locale).toUpperCase()}
      />
      <div id="CookieDeclaration" />
    </Container>
  );
};

CookieBotDeclarationBlock.propTypes = {
  value: PropTypes.shape({
    buttonText: PropTypes.string,
    heading: PropTypes.string,
    text: PropTypes.string,
  }).isRequired,
};

export default CookieBotDeclarationBlock;
