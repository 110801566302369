import styled from 'styled-components';

import { getThemeColor, hexToRgba, media } from '@utils/styled';

import Typography from '@common/components/Typography';

export const ImageWrapper = styled.div`
  position: relative;
`;

export const CardContainer = styled.div`
  position: relative;
  padding: 0;

  img {
    display: block;
  }
`;

export const LabelBackdrop = styled.div`
  position: absolute;
  ${({ theme }) => theme.getFluidSpacing('bottom', 'equal', 5)};
  ${({ theme }) => theme.getFluidSpacing('left', 'equal', 5)};
  ${({ theme }) => theme.getFluidSpacing('right', 'equal', 5)};
  border-radius: ${({ theme }) => theme.borderRadiuses['normal.2']}rem;
  background-color: ${({ theme }) =>
    hexToRgba(getThemeColor('grey.100')({ theme }), 0.6)};
  backdrop-filter: blur(10px);

  ${media.from640up`
    ${({ theme }) => theme.getFluidSpacing('bottom', 'equal', 3)};
    ${({ theme }) => theme.getFluidSpacing('left', 'equal', 3)};
    ${({ theme }) => theme.getFluidSpacing('right', 'equal', 3)};
  `};
`;

export const Label = styled.div`
  width: 100%;
  padding: 20px;
`;

export const Name = styled(Typography).attrs({ variant: 'h6' })`
  margin-bottom: 0;
`;

export const Position = styled(Typography).attrs({ variant: 'body2' })`
  opacity: 0.5;
  margin-bottom: 0;
  font-weight: 500;
`;
