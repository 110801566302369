import React from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';

import { videoShape } from '@common/types/cms';

import {
  Container,
  Content,
  ImageContainer,
  ImageWrapper,
  LazyImage,
} from './TeaserBlockBase.styled';

const LinkWrapper = ({ children, link, isLinkExternal }) => {
  if (link && !isLinkExternal) {
    return (
      <Link {...link} legacyBehavior>
        <a>{children}</a>
      </Link>
    );
  }

  if (link && isLinkExternal) {
    return <a {...link}>{children}</a>;
  }

  return children;
};

LinkWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  link: PropTypes.shape({
    href: PropTypes.oneOfType([
      PropTypes.shape({
        pathname: PropTypes.string,
        query: PropTypes.shape({}),
      }),
      PropTypes.string,
    ]),
    as: PropTypes.string,
  }),
  isLinkExternal: PropTypes.bool,
};

LinkWrapper.defaultProps = {
  link: null,
  isLinkExternal: false,
};

const TeaserBlockBase = ({
  image,
  children,
  index,
  asHero,
  link,
  isLinkExternal,
  reverseLayout,
  video,
  videoDesktop,
  imageContainerAddon,
  ...props
}) => (
  <Container {...props} reverseLayout={reverseLayout} asHero={asHero}>
    <ImageContainer reverseLayout={reverseLayout} asHero={asHero}>
      <ImageWrapper
        asHero={asHero}
        reverseLayout={reverseLayout}
        hasAddon={!!imageContainerAddon}
      >
        <LinkWrapper link={link} isLinkExternal={isLinkExternal}>
          <LazyImage
            widths={[1050, 950, 810, 710, 380, 800, 640, 480, 400]}
            aspectRatios={[
              '1.5:1',
              '1.5:1',
              '1.5:1',
              '1:1.3',
              '1:1.3',
              '1.3:1',
              '1:1',
              '4:5',
              '4:5',
            ]}
            breakpoints={[1600, 1375, 1200, 1024, 880, 640, 480, 400, 320]}
            src={image?.url}
            alt={image?.altText}
            forceShow={index < 2}
            asHero={asHero}
            video={video}
            videoDesktop={videoDesktop}
          />
        </LinkWrapper>
      </ImageWrapper>
      {imageContainerAddon}
    </ImageContainer>
    <Content asHero={asHero}>{children}</Content>
  </Container>
);

TeaserBlockBase.propTypes = {
  asHero: PropTypes.bool,
  children: PropTypes.node.isRequired,
  isLinkExternal: PropTypes.bool,
  image: PropTypes.shape({
    altText: PropTypes.string,
    url: PropTypes.string.isRequired,
  }).isRequired,
  video: videoShape,
  videoDesktop: videoShape,
  imagePlaceholder: PropTypes.shape({
    url: PropTypes.string,
  }),
  index: PropTypes.number.isRequired,
  link: PropTypes.shape({
    href: PropTypes.oneOfType([
      PropTypes.shape({
        pathname: PropTypes.string,
        query: PropTypes.shape({}),
      }),
      PropTypes.string,
    ]),
    as: PropTypes.string,
  }),
  reverseLayout: PropTypes.bool.isRequired,
  imageContainerAddon: PropTypes.node,
};

TeaserBlockBase.defaultProps = {
  asHero: false,
  imagePlaceholder: undefined,
  link: null,
  video: null,
  videoDesktop: null,
  isLinkExternal: false,
  imageContainerAddon: null,
};

LinkWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  isLinkExternal: PropTypes.bool,
  link: PropTypes.shape({
    href: PropTypes.oneOfType([
      PropTypes.shape({
        pathname: PropTypes.string,
        query: PropTypes.shape({}),
      }),
      PropTypes.string,
    ]),
    as: PropTypes.string,
  }),
};

LinkWrapper.defaultProps = {
  link: null,
  isLinkExternal: false,
};

export default TeaserBlockBase;
