import styled from 'styled-components';

import { getThemeColor, getTypographyStyle, media } from '@utils/styled';

import LazyImg from '@common/components/LazyImg';
import Typography from '@common/components/Typography';
import Carousel from '@common/components/Carousel';

export const Container = styled.div`
  position: relative;
  overflow: hidden;
  ${({ theme }) => theme.getFluidAttribute('margin-bottom', 4.5, 6)};
  ${({ theme }) => theme.getFluidSpacing('margin-left', 'widthBased', 0, true)};
  ${({ theme }) =>
    theme.getFluidSpacing('margin-right', 'widthBased', 0, true)};

  &::before {
    content: '';
    position: absolute;
    z-index: -2;
    width: 100%;
    height: 15rem;
    background: ${getThemeColor('brand.bluegreyGradient')};
  }

  ${media.from880up`
    ${({ theme }) => theme.getFluidAttribute('margin-bottom', 2.5, 4)};
    &::before {
      display: none;
    }
  `}
`;

export const Title = styled(Typography).attrs({
  customAs: 'h2',
  variant: 'h5',
})`
  ${({ theme }) => theme.getFluidSpacing('margin-bottom', 'scale', 4)};
  color: ${getThemeColor('bluegrey.60')};
  text-align: center;

  ${media.from640up`
    ${getTypographyStyle('h4')}
  `}

  ${media.from880down`
    padding-top: 3rem;
  `}
`;

export const StyledCarousel = styled(Carousel)`
  ${({ theme }) => theme.getFluidSpacing('margin-left', 'widthBased', 0)};
  ${({ theme }) => theme.getFluidSpacing('margin-right', 'widthBased', 0)};
`;

export const CategoryItemLink = styled.a`
  position: relative;
  overflow: hidden;
  display: block;
  width: 100%;
  border-radius: ${({ theme }) => theme.borderRadiuses['normal.2']}rem;
`;

export const CategoryImage = styled(LazyImg)`
  img {
    display: block;
  }

  picture::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.06) 0%,
      rgba(0, 0, 0, 0.2) 100%
    );
  }
`;

export const CategoryTitle = styled(Typography).attrs({
  variant: 'h5',
})`
  position: absolute;
  bottom: 2rem;
  left: 2.3rem;
  margin: 0;
  color: ${getThemeColor('grey.100')};

  ${media.from800up`
    ${getTypographyStyle('h4')}
  `}
`;
