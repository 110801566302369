import PropTypes from 'prop-types';
import React from 'react';

import Accordion from '@common/components/Accordion/Accordion';
import HTMLRenderer from '@common/components/CMSComponents/HTMLRenderer';
import { BodyAttributes } from '@common/contexts/bodyAttributesContext';

import {
  AccordionBlockStyled,
  Intro,
  ItemsContainer,
  Title,
} from './AccordionBlock.styled';

const AccordionBlock = ({ value }) => {
  const { title, intro, items } = value;

  return (
    <AccordionBlockStyled>
      <BodyAttributes itemScope itemType="https://schema.org/FAQPage" />
      <div>
        <Title>{title}</Title>
        {intro && (
          <Intro>
            <HTMLRenderer html={intro} />
          </Intro>
        )}
        <ItemsContainer>
          {items.map((item, index) => (
            <Accordion
              key={item.title}
              title={item.title}
              isFirstItem={index === 0}
              isQuestionAnswer
            >
              <HTMLRenderer html={item.text} />
            </Accordion>
          ))}
        </ItemsContainer>
      </div>
    </AccordionBlockStyled>
  );
};

AccordionBlock.propTypes = {
  value: PropTypes.shape({
    intro: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
};

export default AccordionBlock;
