import styled, { css } from 'styled-components';

import { breakpoints } from '@themes/main';

import { getThemeColor, media } from '@utils/styled';
import { getFluidSizingFormula } from '@utils/sizing';

import Typography from '@common/components/Typography';
import LazyImg from '@common/components/LazyImg';

export const Container = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-shadow: ${({ theme }) => theme.shadows[0]};
  margin: 0 auto;
  ${({ theme }) => theme.getFluidSpacing('margin-bottom', 'section', 3)};
  border-radius: ${({ theme, asHero }) =>
    !asHero ? theme.borderRadiuses['normal.2'] : 0}rem;

  ${({ asHero, theme }) =>
    asHero &&
    css`
      ${theme.getFluidSpacing(
        'margin-right',
        'widthBased',
        0,
        true,
        'safe-area-inset-right',
      )};
      ${theme.getFluidSpacing(
        'margin-left',
        'widthBased',
        0,
        true,
        'safe-area-inset-right',
      )};
    `}

  ${media.from880up`
    ${({ theme }) => theme.getFluidSpacing('margin-bottom', 'scale', 0)};
    flex-direction: ${({ reverseLayout }) => (reverseLayout ? 'row-reverse' : 'row')};
    margin-right: 0;
    margin-left: 0;
    border-radius: ${({ theme }) => theme.borderRadiuses['normal.2']}rem;
  `}
`;

const leftImageBorderRadius = css`
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  background: hotpink;
`;

const rightImageBorderRadius = css`
  border-top-right-radius: ${({ theme }) =>
    theme.borderRadiuses['normal.2']}rem;
  border-bottom-right-radius: ${({ theme, hasAddon }) =>
    !hasAddon && theme.borderRadiuses['normal.2']}rem;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  background: gold;
`;

const noBorderRadius = css`
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
`;

const getImageBorderRadius = ({ asHero, reverseLayout }) => {
  if (asHero) {
    return noBorderRadius;
  }

  if (reverseLayout) {
    return rightImageBorderRadius;
  }

  return leftImageBorderRadius;
};

const heightSizingFormula = getFluidSizingFormula(
  50,
  60,
  breakpoints[0],
  breakpoints[18],
  'vh',
);

export const ImageContainer = styled.div`
  overflow: hidden;
  display: block;
  flex: 0 0 40%;
  line-height: 0;

  ${media.from880up`
    flex-basis: 41.66%;
  `}

  ${media.from1040up`
    flex-basis: 50%;
  `}

  ${media.from1200up`
    flex-basis: 58.33%;
  `}

  ${media.from1440up`
    flex-basis: 66.66%;
  `}
`;

export const ImageWrapper = styled.div`
  position: relative;
  overflow: hidden;

  a {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
    line-height: 0;
  }

  a > div {
    position: absolute;
    top: 0;
    bottom: 0;
  }

  a::after {
    display: none !important;
  }

  & img {
    height: 100%;
    ${({ asHero, reverseLayout }) =>
      getImageBorderRadius(asHero, reverseLayout)};
    background-color: ${getThemeColor('bluegrey.95')};
    object-fit: cover;
    object-position: center;
  }

  & video {
    height: 100%;
    ${({ asHero, reverseLayout }) =>
      getImageBorderRadius(asHero, reverseLayout)};
  }

  ${({ asHero }) =>
    !asHero &&
    css`
      &::before {
        content: '';
        display: block;
        ${({ theme }) =>
          theme.getFluidAttribute('height', 50, 60, heightSizingFormula, 'vh')};
        ${({ theme }) => theme.getFluidAttribute('max-height', 36, 80)};
        ${({ theme }) => theme.getFluidAttribute('min-height', 24, 40)};
      }
    `}

  &::before {
    content: '';
    display: block;
    ${({ theme }) =>
      theme.getFluidAttribute('height', 50, 60, heightSizingFormula, 'vh')};
    ${({ theme }) => theme.getFluidAttribute('max-height', 36, 80)};
    ${({ theme }) => theme.getFluidAttribute('min-height', 24, 40)};
  }

  ${({ asHero }) =>
    asHero &&
    css`
      ${media.from880down`
        &::before {
          content: '';
          display: block;
          height: 70vh;
          ${({ theme }) => theme.getFluidSpacing('max-height', 'heroImage', 1)};
          ${({ theme }) => theme.getFluidSpacing('min-height', 'heroImage', 0)};
        }
      `}
    `}
`;

export const LazyImage = styled(LazyImg)`
  ${({ asHero }) =>
    asHero &&
    css`
      ${media.from880down`
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
      }
      `}
    `}
`;

export const Content = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  ${({ theme, asHero }) =>
    theme.getFluidSpacing(
      'padding',
      'widthBased',
      0,
      false,
      asHero ? 'safe-area-inset-left' : undefined,
    )};
  ${({ theme }) => theme.getFluidSpacing('padding-top', 'equal', 8)};
  ${({ theme }) => theme.getFluidSpacing('padding-bottom', 'equal', 8)};

  ${media.from880up`
    ${({ theme }) => theme.getFluidSpacing('padding', 'widthBased', 2)};
    ${({ theme }) => theme.getFluidSpacing('padding-right', 'widthBased', 3)};
  `}
`;

export const Label = styled(Typography).attrs({
  variant: 'body2',
})`
  color: ${getThemeColor('bluegrey.60')};
  font-weight: 500;
  ${({ theme }) => theme.getFluidSpacing('margin-bottom', 'scale', 6)};
`;

export const Title = styled(Typography).attrs({
  customAs: 'h2',
  variant: 'h3',
})`
  ${({ theme }) => theme.getFluidSpacing('margin-bottom', 'scale', 6)};
`;

export const Description = styled(Typography).attrs({
  variant: 'paragraph1',
  customAs: 'div',
})`
  ${({ theme }) => theme.getFluidSpacing('margin-bottom', 'scale', 5)};
  color: ${getThemeColor('bluegrey.15')};

  ${({ clamp }) =>
    clamp &&
    css`
      overflow: hidden;
      display: none;

      ${media.from880up`
      display: block;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    `}
    `}
`;
