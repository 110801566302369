import styled from 'styled-components';

import { getThemeTransition, getThemeColor } from '@utils/styled';

import CloseButton from '@common/components/CloseButton';
import Hr from '@common/components/Hr';
import Typography from '@common/components/Typography';
import { Plus } from '@common/components/Icons';

export const Divider = styled(Hr)`
  margin: 0;
  padding: 0;
`;

export const ToggleButton = styled(CloseButton).attrs({ Icon: Plus })`
  position: relative;
  top: -0.1rem;
  right: -0.2rem;
  border-color: transparent;
  padding: 0;
  background: none;
  transform: scale(1);

  svg {
    transition: ${getThemeTransition('fill')},
      ${getThemeTransition('transform', 'fast')};
    transform: scale(1);
    ${({ isToggled }) => isToggled && 'transform: rotate(45deg)'};
  }

  &:hover,
  &:focus,
  &:active {
    border-color: transparent;
    background-color: transparent;
  }
`;

export const Title = styled(Typography).attrs({
  variant: 'h6',
  customAs: 'h4',
})`
  margin-bottom: 0;
`;

export const Content = styled.div`
  overflow: hidden;
  max-height: ${({ isToggled, maxHeight }) => (isToggled ? maxHeight : 0)};
  ${({ isToggled }) => !isToggled && 'margin-bottom: 0'};
  ${({ theme, isToggled }) =>
    isToggled && theme.getFluidSpacing('margin-bottom', 'equal', 2)};
  transition:
    max-height ${({ duration }) => duration} ease-in-out,
    ${getThemeTransition('margin-bottom', 'fast')};
`;

export const TopHr = styled(Hr)`
  margin: 0;
`;

export const TitleButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  cursor: pointer;
  ${({ theme }) => theme.getFluidSpacing('padding-top', 'equal', 2)};
  ${({ theme }) => theme.getFluidSpacing('padding-bottom', 'equal', 2)};
`;

export const ContentWrapper = styled.div`
  &:hover,
  &:focus,
  &:active {
    ${Title} {
      color: ${getThemeColor('bluegrey.40')};
    }

    ${ToggleButton} {
      border-color: transparent;
      background-color: transparent;

      svg {
        fill: ${getThemeColor('bluegrey.40')};
      }
    }
  }
`;
